@charset "utf-8";
@import "defaults";

$color_1: #111111;
$color_2: $primary_color;
$color_3: #ffffff;
$font_family_1: 'Poppins', sans-serif;

html, body {
    height: 100%;
    font-size: 15px;
    color: #505050;
    font-family: 'Poppins', sans-serif;
    vertical-align: baseline;
    line-height: 25px;
    font-weight: 400;
    background: #ffffff;
}

/* ....................................
1. General CSS
.......................................*/
body {
    .ohz5-ad-container {
        margin: 10px 0;

        .advertisement-area-title {
            color: #555;
            margin: 0;
            padding: 0;
        }

        .advertisement-area {
            border: 1px solid #f5f5f5;
            padding: 2px;

            .adsbygoogle {
                margin: 0;
                padding: 0;

                * {
                    max-width: 100%;
                }
            }
        }

        &.dark {
            .advertisement-area-title {
                color: #fff;
            }

            .advertisement-area {
                border-color: #666;
            }
        }
    }

    table {
        width: 100%;
    }

    .sec-spacer {
        padding: 100px 0;
    }

    .text-white {
        color: #fff;
    }

    span {
        &.date {
            i,
            svg {
                margin-right: 5px;
            }
        }
    }

    .infinite-scroll {
        width: 100%;

        &-loader {
            width: 100%;
        }
    }

    .primary-btn {
        background: $primary_color;
        color: #111111;

        &:hover {
            color: #ffffff !important;
        }

        padding: 8px 25px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        display: inline-block;
        font-family: 'Poppins', sans-serif;
        transition: 0.4s;
    }

    .clear {
        clear: both;
    }

    .btn-small {
        background: $primary_color;
        color: #ffffff;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 600;
        line-height: 15px;
        padding: 6px 14px;
    }

    $padding_top: 5;
    @while $padding_top <= 100 {
        .pt-#{$padding_top} {
            padding-top: #{$padding_top}px;
        }
        $padding_top: $padding_top + 5;
    }
    $padding_bottom: 10;
    @while $padding_bottom <= 100 {
        .pb-#{$padding_bottom} {
            padding-bottom: #{$padding_bottom}px;
        }
        $padding_bottom: $padding_bottom + 10;
    }

    img {
        max-width: 100%;
        height: auto;
        transition: all 0.8s ease-in-out 0s;
    }

    p {
        margin: 0 0 26px;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0 0 26px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        color: #111111;

        small {
            font-weight: 400;
            line-height: 1;
            color: #777;
            font-size: 65%;
        }
    }

    h1 {
        font-size: 40px;
        font-weight: 600;
    }

    h2 {
        font-size: 36px;
        font-weight: 600;
    }

    h3 {
        font-size: 30px;
        font-weight: 600;
    }

    h4 {
        text-transform: capitalize;
        font-size: 24px;
        font-weight: 500;
    }

    h5 {
        font-size: 20px;
        font-weight: 400;
    }

    h6 {
        font-size: 16px;
        font-weight: 400;
    }

    a {
        outline: 0 solid;
        transition: all 0.4s ease 0s;
        text-decoration: none;

        &:hover {
            color: $primary_color;
            text-decoration: none;
        }

        &:active, &:hover {
            outline: 0 none;
            color: $primary_color;
            text-decoration: none;
        }
    }

    ul {
        list-style: outside none none;
        margin: 0;
        padding: 0;
    }

    ::selection {
        background: $primary_color;
        text-shadow: none;
        color: #ffffff;
    }

    .overlay-bg {
        width: 100%;
        height: 100%;
        background: transparentize( #000, .3 );
        top: 0;
        left: 0;
    }

    .text-right {
        text-align: right;
    }

    .text-left {
        text-align: left;
    }

    .title-bg {
        position: relative;
        font-size: 24px;
        color: #111111;
        display: inline-block;
        text-transform: capitalize;
        margin-bottom: 44px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        line-height: 17px;
        padding-bottom: 10px;

        &:after {
            background: $primary_color;
            width: 50%;
            height: 4px;
            left: 0;
            position: absolute;
            content: "";
            bottom: -14px;
        }

        &:before {
            height: 4px;
            left: 0;
            position: absolute;
            content: "";
            bottom: -14px;
            background: #999999;
            width: 100%;
        }
    }

    .menu-sticky.sticky {
        background-color: rgba(0, 0, 0, 0.95);
        position: fixed !important;
        z-index: 9999;
        width: 100%;
        top: 0;

        .logo img {
            max-width: 75px;
            transition: 0.4s;
        }
    }
}

/* ------------------------------------
2.Header area start here
---------------------------------------*/
.header-top-area {
    padding: 5px 0;
    font-size: 12px;
    line-height: 28px;
    background: $primary_color;

    .header-top-left ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            float: left;
            color: #111111;
            line-height: 32px;
            transition: all 0.4s ease 0s;

            a {
                transition: all 0.4s ease 0s;
                color: #111111;
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-weight: 400;

                i {
                    font-weight: 400;
                }

                &:hover {
                    color: #505050;
                }
            }

            ul {
                overflow: hidden;
                opacity: 0;
                visibility: hidden;
                margin: 0;
                padding: 5px 0;
                position: absolute;
                width: 180px;
                z-index: 12;

                li {
                    line-height: 20px;

                    a {
                        font-weight: 400;
                    }
                }
            }

            &:last-child {
                margin-left: 30px;

                img {
                    margin-right: 3px;
                    margin-top: -4px;
                    width: 22px;
                    border-radius: 2px;
                }

                i {
                    position: relative;
                    top: 1px;
                }

                ul {
                    margin-left: -24px;

                    li img {
                        margin-right: 5px;
                    }
                }
            }

            &:hover ul {
                opacity: 1;
                overflow: visible;
                visibility: visible;
                background: $primary_color;

                li {
                    margin-left: 24px;
                }
            }
        }
    }

    .social-media-area {
        ul {
            text-align: right;

            li {
                display: inline-block;

                a {
                    display: block;
                    color: #111111;
                    transition: all 0.4s ease 0s;
                    text-decoration: none;
                    text-align: center;
                    font-size: 22px;
                    margin-right: 15px;

                    &:hover {
                        color: #644d12;
                    }

                    i {
                        text-align: center;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }

        .log,
        .sign {
            font-weight: 400;

            a {
                margin-right: 6px;
                color: #111111;
                font-size: 14px;

                &:hover {
                    color: #505050;
                }
            }
        }

        .log {
            margin-left: 15px;
        }
    }
}

.header-middle-area {
    background: #111111;

    .main-menu {
        position: relative;

        .navbar {
            margin: 0;

            .navbar-nav {
                a {
                    color: $white;

                    &.active {
                        color: $primary_color;
                    }
                }

                .nav-item {
                    .dropdown-menu {
                        background: #111111;

                        .dropdown-item {
                            &:hover,
                            &:focus,
                            &:active {
                                background: lighten(#111111, 40%);
                            }
                        }
                    }
                }
            }
        }
    }

    .item {
        position: relative;
        z-index: 11;
    }

    .container {
        position: relative;
    }

    .logo {
        position: relative;
        z-index: 999;
        height: 57px;
        padding-top: 10px;
        transition: 0.4s;

        img {
            transition: 0.4s;
            max-width: 120px !important;
        }
    }
}

.gallery-section-area {
    .gallery-items {
        margin: 0 auto;

        .grid-item, .single-gallery {
            margin: 0 0 30px;
            text-align: center;
            position: relative;
            overflow: hidden;

            &:after {
                content: "";
                width: 0;
                height: 0;
                background: #000;
                opacity: 0.6;
                position: absolute;
                transform: skewY(140deg);
                transition: 0.4s;
                top: -139px;
                left: 0;
            }

            &:before {
                content: "";
                width: 0;
                height: 0;
                background: #000;
                opacity: 0.6;
                position: absolute;
                transform: skewY(140deg);
                transition: 0.4s;
                bottom: -139px;
                right: 0;
            }

            img {
                padding: 0;
                width: 100%;
            }

            .images-background {
                width: 100%;
                height: 275px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }

            .bottom-icons {
                opacity: 0;
                visibility: hidden;
                transition: 0.5s;
                position: absolute;
                bottom: 30px;
                right: 20px;
                z-index: 11;

                ul {
                    li {
                        display: inline-block;

                        i {
                            color: $color_2;
                            padding-left: 15px;
                        }
                    }
                }
            }

            .heading-content {
                opacity: 0;
                visibility: hidden;
                transition: 0.5s;
                position: absolute;
                top: 30px;
                left: 20px;
                z-index: 11;

                h4 {
                    text-align: left;
                    color: $color_3;
                    margin: 0 0 6px;
                    font-size: 18px;
                    font-family: $font_family_1;
                    font-weight: 500;

                    span.date {
                        font-size: 14px;
                    }
                }

                ul {
                    text-align: left;

                    li {
                        display: inline-block;

                        i {
                            color: $color_2;
                        }
                    }
                }
            }

            &:hover,
            &.show-heading {
                &:after {
                    width: 240px;
                    height: 240px;
                }

                &:before {
                    width: 240px;
                    height: 240px;
                }

                .bottom-icons {
                    opacity: 1;
                    visibility: visible;
                }

                .heading-content {
                    opacity: 1;
                    visibility: visible;
                }
            }

            &.show-heading {
                &:before {
                    display: none;
                }
            }
        }
    }
}

.match-summary {
    .row {
        .item {
            table {
                tr {
                    td {
                        border: 2px solid #f5f5f5;
                        border-bottom: 0;
                        padding: 5px 10px;

                        &.no-border-right {
                            border-right: 0;
                        }

                        &.no-border-left {
                            border-left: 0;
                        }
                    }
                }
            }

            img, .match-summary-content {
                display: inline-block;
            }

            img {
                width: 20px;
                max-width: 20px;
            }

            .match-summary-content {
                width: 100%;

                p {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    line-height: normal;

                    &.sub-item {
                        color: darkgrey;
                        font-size: 12px;
                    }
                }
            }
        }

        &:first-child {
            .item {
                table {
                    tr {
                        td {
                            border-top: 2px solid #f5f5f5;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        &:last-child {
            .item {
                table {
                    tr {
                        td {
                            border-bottom: 2px solid #f5f5f5;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    &.match-summary-motm {
        .row {
            .item {
                table {
                    tr {
                        td {
                            border-left: 2px solid #f5f5f5;
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }
}

/* ------------------------------------
. Main
---------------------------------------*/
.main-section {
    position: relative;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;

    .row {
        h1 {
            color: #fff;
            font-size: 60px;

            span {
                color: $primary_color;
                margin: 0 20px;
            }
        }
    }

    &.big-image {
        .container {
            > .row {
                padding-top: 10vh;
                padding-bottom: 10vh;
            }
        }
    }
}

/* ------------------------------------
4. RS History Section Start Here
---------------------------------------*/
.our-history-sections {
    position: relative;
    z-index: 11;
    overflow: hidden;
    background-color: #262a33;

    &:after, &:before {
        position: absolute;
        background-size: contain;
        content: "";
        height: 100%;
        width: 35%;
        bottom: 0;
    }

    &:after {
        left: 0;
    }

    &:before {
        right: 0;
    }

    .history-inner {
        padding: 0 75px;

        .history-details {
            h3 {
                font-family: 'Poppins', sans-serif;
                font-weight: 300;
                color: #ffffff;
                line-height: 40px;
                margin: 0 0 20px;
            }

            p {
                font-size: 15px;
                color: #cccccc;

                .fa {
                    font-size: 35px;
                    color: #fff;
                    padding: 20px 8px 0;

                    &:hover, &:focus {
                        color: $primary_color;
                    }
                }
            }
        }
    }
}

/* ------------------------------------
5. Upcoming Section Start Here
---------------------------------------*/
.upcoming-section-container {
    background: $primary_color;

    .upcoming-section {
        padding: 50px 0;
        margin-top: -1px;
        color: #fff;
        text-align: center;

        .owl-nav i:hover {
            color: #fff;
        }

        .container {
            max-width: 900px;
            margin: 0 auto;
        }

        h2 {
            text-align: center;
            color: #fff;
            margin-bottom: 40px;
            font-weight: 600;
            letter-spacing: 1px;
        }

        h4 {
            color: #fff;
            font-size: 15px;
            font-weight: 400;
            margin: 14px 0 0;
            text-transform: uppercase;
        }

        span {
            display: block;
            text-transform: uppercase;
            font-size: 15px;
            margin-right: 0;

            &.date {
                padding-top: 10px;
                font-weight: 500;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
            }
        }

        .last img {
            margin-top: 20px;
        }

        .vs {
            color: #fff;
            font-size: 25px;
            font-weight: 600;
            padding: 50px 0;
        }
    }
}

.carousel {
    .slider {
        .slide {
            background: none;
        }
    }
}

/* ------------------------------------
6 Latest News Area Start Here
---------------------------------------*/
.latest-news {
    .inner-news {
        .news-text {
            span {
                font-family: $font_family_1;
                font-size: 12px;
                text-transform: uppercase;
            }

            ul.rating {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    display: inline-block;
                    padding-right: 4px;

                    i {
                        color: $color_2;
                    }
                }
            }
        }

        &:hover {
            .news-text {
                h5 {
                    a {
                        color: $color_2;
                    }
                }
            }

            .primary-btn {
                color: $color_3;
            }

            .news-img {
                img {
                    filter: grayscale(100%);
                    opacity: .8;
                }
            }
        }
    }

    .small-news {
        overflow: hidden;

        .news-img {
            float: left;
            margin-right: 15px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 150px;
        }

        .news-text {
            overflow: hidden;

            h5 {
                margin: 0 0 15px;
                font-weight: 400;
                line-height: 18px;

                a {
                    font-family: $font_family_1;
                    color: $color_1;
                    font-size: 15px;
                }
            }
        }

        & + .small-news {
            margin-top: 15px;
        }
    }
}

.all-news-area {
    .news-normal-block {
        padding: 15px;
        border: 0;
        transition: all 0.4s ease 0s;

        &:hover {
            box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
        }

        .news-img {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            max-width: 100%;
            max-height: 250px;
            margin: 10px auto;

            img {
                width: 100%;
            }
        }

        .news-date {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 5px;

            i {
                font-size: 13px;
                font-weight: 500;
                display: inline-block;
                margin-right: 4px;
                color: $primary_color;
            }

            span {
                color: #505050;
            }
        }

        .news-title {
            font-size: 18px;
            margin: 12px 0;

            a {
                color: #212121;

                &:hover, &:focus {
                    color: $primary_color;
                }
            }
        }

        .news-desc p {
            margin-bottom: 20px;
        }

        .news-btn a {
            display: inline-block;
            margin-left: auto;
            padding: 4px 16px;
            font-size: 13px;
            font-weight: 500;
            text-decoration: none;
            background-color: #111111;
            color: #fff;

            &:hover {
                background-color: $primary_color;
            }
        }
    }
}

/* ------------------------------------
07.Last Match Results
---------------------------------------*/
.today-match-teams {
    background-size: cover;
    position: relative;

    .today-results-table, .title-head, .today-score {
        position: relative;
        z-index: 11;
    }

    .title-head {
        padding: 25px 16px 20px;

        h4 {
            color: #ffffff;
            font-size: 18px;
            text-transform: capitalize;
            margin: 0 0 4px;
            font-weight: 500;
        }

        span {
            color: #cccccc;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 600;
        }
    }

    .today-score {
        overflow: hidden;
        padding: 0 10px 12px;

        .today-match-team, .today-final-score {
            display: inline-block;
            vertical-align: top;
        }

        .today-match-team {
            width: 40%;

            h4 {
                margin: 8px 0 0;
                color: #ffffff;
                font-size: 14px;
                text-transform: uppercase;
            }

            span {
                font-size: 12px;
                color: #cccccc;
                text-transform: uppercase;
            }

            img {
                border-radius: 5px;
                max-width: 80%;
                max-height: 80%;
            }
        }

        .today-final-score {
            width: 20%;
            font-weight: 600;
            color: #ffffff;
            font-size: 24px;
            line-height: 130px;

            span {
                color: $primary_color;
            }

            h4 {
                margin: 16px 0 0;
                color: $primary_color;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 15px;
                text-transform: uppercase;
            }
        }
    }

    .today-results-table {
        tr {
            border-top: 1px solid #555555;

            td {
                font-family: 'Poppins', sans-serif;
                font-size: 12px;
                color: #cccccc;
                padding: 9px 15px;
                font-weight: 600;

                &.tb-title {
                    text-align: left;
                    color: #ffffff;

                    &.first-child {
                        color: $primary_color;
                    }
                }
            }

            &:first-child {
                position: relative;

                &:after {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 41px;
                    top: 0;
                    left: 0;
                    background: #111111;
                    opacity: 0.6;
                    overflow: hidden;
                }

                td {
                    color: $primary_color;
                    font-size: 14px;
                    position: relative;
                    z-index: 11;

                    &.tb-title {
                        color: $primary_color;
                        font-size: 14px;
                        position: relative;
                        z-index: 11;
                    }
                }
            }
        }

        .view-score {
            border-top: 1px solid #555555;

            a {
                color: $primary_color;
                display: block;
                height: 56px;
                line-height: 56px;

                &:hover {
                    color: #ffffff;
                }
            }
        }
    }
}

/* ------------------------------------
08 Latest video section
---------------------------------------*/
.latest-video-section {
    position: relative;

    .title-bg {
        color: #ffffff;
    }

    .latest-video, .latest-news, .title-bg {
        position: relative;
        z-index: 100;
    }

    span.date {
        color: $primary_color;
        font-size: 12px;
        font-weight: 600;
    }

    .latest-video {
        > img {
            width: 100%;
        }

        img {
            max-height: 420px;
        }

        max-height: 420px;
        position: relative;

        a.popup-youtube img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 100;
        }
    }

    .small-news .news-text {
        h5 {
            margin: 0 0 5px;
            line-height: 22px;

            a {
                color: #ffffff;
                font-size: 15px;
                font-weight: 400;
            }
        }

        span.date {
            color: $primary_color;
            font-size: 12px;
            font-weight: 600;
        }
    }

    .video-area {
        background: #000;
        position: relative;

        > div {
            width: auto !important;
        }

        img {
            &.video-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 11;
                opacity: 1;

                &:hover {
                    opacity: 0.8;
                }
            }

            opacity: 0.6;
            transition: opacity 0.35s;
            z-index: 1;
        }
    }
}

/* ------------------------------------
10 Our Team Section
---------------------------------------*/
.our-team-section {
    .our-team {
        overflow: hidden;
        position: relative;
        text-align: center;

        .player-image {
            background-size: contain;
            background-position: center bottom;
            background-repeat: no-repeat;
            width: 100%;
            height: 250px;
        }

        .player-image-page {
            background-size: contain;
            background-position: center bottom;
            background-repeat: no-repeat;
            width: 100%;
            height: 260px;
        }

        .person-details {
            padding: 20px 15px;
            position: absolute;
            bottom: -90px;
            min-height: 140px;
            left: 0;
            width: 100%;
            transition: 0.4s;
            text-align: left;

            .overlay-bg {
                background: transparentize( $primary_color, .3 );
            }

            h3.person-name,
            h5.person-name,
            .person-info {
                position: relative;
                z-index: 11;
                color: #111111;
            }

            h5.person-name,
            h3.person-name {
                font-size: 15px;
                font-weight: 600;
                text-transform: uppercase;
                margin: 0 0 10px;
                transition: 0.4s;
            }

            h5.person-name:hover,
            h3.person-name:hover {
                color: #fff;
            }

            .person-info {
                font-weight: 500;
                font-size: 12px;

                ul.person-social-icons li {
                    display: inline-block;
                    padding-left: 8px;

                    &:first-child {
                        padding-left: 0;
                    }

                    i {
                        color: #111111;
                        transition: 0.4s;

                        &:hover {
                            color: #ffffff;
                        }
                    }
                }
            }
        }

        &:hover .person-details {
            bottom: 0;
        }
    }

    &.team-inner-page {
        padding-bottom: 70px;

        .our-team {
            margin-bottom: 30px;
        }
    }

    .best-footballer {
        background: $primary_color;
        padding: 40px 25px 45px;

        h4 {
            font-size: 18px;
            margin: 0 0 20px;
            line-height: 26px;
            text-transform: capitalize;
        }

        ul#player-list li {
            padding-bottom: 6px;

            span {
                color: #111111;
                font-weight: 500;
                margin-left: 15px;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }

        .submit-area {
            margin-top: 22px;

            input {
                border: 1px solid #111111;
                border-radius: 0;
                background: #111111;
                color: #fff;
                text-align: center;
                transition: 0.4s;

                &:hover {
                    background: #a77b0c;
                }
            }
        }
    }
}

/* ------------------------------------
11 Team Single Section
---------------------------------------*/
.rs-team-single-section {
    .rs-team-single-text {
        h3 {
            font-size: 24px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
        }

        .single-details {
            padding: 20px;
            margin: 0;
            background-color: $primary_color;

            .single-team-info {
                padding: 0;

                table tr {
                    td {
                        h3 {
                            font-size: 15px;
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            margin: 0 0 15px;
                        }

                        h4 {
                            font-size: 15px;
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            color: #505050;
                            margin: 0 0 15px;
                        }

                        i {
                            margin: 0 10px 0 0;
                            color: #505050;

                            &:hover {
                                color: #ffffff;
                            }
                        }
                    }

                    &:last-child h3 {
                        margin: 0;
                    }
                }
            }

            .single-team-text {
                padding: 0;

                .text-section p {
                    font-size: 15px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    color: #000;
                    margin: 0;
                    font-style: italic;
                }

                .signature-section {
                    .sign-left {
                        float: left;

                        h1 {
                            font-size: 15px;
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            margin: 25px 0 0;
                        }
                    }

                    .sign-right {
                        float: right;
                    }
                }
            }
        }

        .team-information-text {
            padding-top: 30px;

            p {
                margin-bottom: 15px;
                font-family: 'Poppins', sans-serif;
                font-size: 15px;
                font-weight: 400;
            }
        }
    }
}

/* ------------------------------------
16 Client Logo Section
---------------------------------------*/
.client-logo-section {
    position: relative;
    background-size: cover;

    .single-logo {
        border: 1px solid $primary_color_dark;
        border-radius: 1px;
        margin-bottom: 50px;
        padding: 18px 0;
        text-align: center;
        background: rgba(0, 0, 0, 0.4);
        transition: 0.4s;

        a {
            display: block;
        }

        &:hover {
            background: #000000;
        }

        &.single-logo-gerardsens {
            background: #fff;
            border-color: #fff;
        }

        &.single-logo-danmel {
            background: #1e3b77;
            border-color: #1e3b77;
        }
    }
}

/* ----------------------------------
17 Blog Section Start Here
-------------------------------------*/
.rs-blog {
    .single-blog-slide {
        min-height: 325px;
        position: relative;
        overflow: hidden;
        margin-bottom: 30px;

        a {
            display: inline-block;
            width: 100%;
            text-align: center;
        }

        .images {
            overflow: hidden;

            img {
                transform: scale(1);
                transition: 0.6s;
            }

            &:hover img {
                transform: scale(1.1);
            }

            &.images-background {
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                max-height: 200px;
                transform: scale(1);
                transition: 0.6s;
                width: auto;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        .blog-details {
            margin: 10px 0 0;
            text-align: left;

            span.date {
                font-weight: 500;
            }

            h3 {
                margin-bottom: 10px;
                line-height: 24px;

                a {
                    color: #111111;
                    transition: all 0.4s ease 0s;
                    font-size: 18px;
                    font-weight: 500;
                    font-family: 'Poppins', sans-serif;
                    text-align: left;

                    &:hover {
                        color: $primary_color;
                    }
                }

                span.date {
                    display: inline-block;
                    width: 100%;
                    font-size: 10px;
                }
            }

            p {
                margin-bottom: 15px;
                color: #111111;
            }

            .read-more {
                display: block;

                a {
                    display: inline-block;
                    color: #111111;
                    transition: all 0.4s ease 0s;
                    font-weight: 400;
                    background: transparent;
                    font-family: 'Poppins', sans-serif;
                    border: 2px solid $primary_color;
                    padding: 5px 16px;
                    border-radius: 2px;
                }
            }
        }

        &:hover {
            .images {
                overflow: hidden;

                img {
                    transform: scale(1.1);
                }
            }

            .read-more a {
                background: $primary_color;
            }
        }
    }

    .owl-nav div {
        height: 30px;
        width: 30px;
        top: 50%;
        border-radius: 3px;
        background: #000;
        opacity: 0.6;
        transform: translateY(-50%);

        &:hover {
            background-color: $primary_color;
            opacity: 0.6;
        }

        i {
            font-size: 25px;
            line-height: 30px;
            position: relative;
            z-index: 99;
            color: #ffffff;
        }
    }
}

/* ------------------------------------
18. Blog Details Start here
---------------------------------------*/
.single-blog-details {
    h3.title-bg {
        &:after, &:before {
            height: 0;
            width: 0;
        }
    }

    .single-image {
        margin-bottom: 25px;
        overflow: hidden;

        video {
            max-width: 100%;
            max-height: 400px;
        }

        img {
            filter: grayscale(0%);
            transition: all 0.8s ease-in-out 0s;

            &:hover {
                filter: grayscale(100%);
                transform: scale(1.1);
            }
        }
    }

    h3 {
        font-size: 18px;
        color: #111111;
        margin: 20px 0;
        padding: 0;
        line-height: 25px;
        font-weight: 500;
    }

    blockquote {
        background: #f0f0f0;
        border: medium none;
        margin: 25px 0;
        padding: 24px 25px 24px 63px;
        font-size: 18px;
        color: #111111;
        font-style: italic;
        position: relative;
        font-weight: 500;

        i {
            position: absolute;
            left: 22px;
            font-size: 25px;
            top: 21px;
        }
    }

    h2 {
        font-size: 20px;
        margin: 0 0 25px;
    }

    .share-section {
        border-top: 1px solid #ddd;
        padding-top: 20px;
        padding-bottom: 20px;

        .share-link1 {
            padding: 0;
            margin: 0;
            float: right;

            li {
                float: left;
                list-style: none;

                &:first-child a {
                    margin-left: 0;
                }

                a {
                    padding: 7px 8px;
                    border: 1px solid #ddd;
                    font-size: 12px;
                    font-weight: 400;
                    color: #505050;
                    border-radius: 4px;
                    margin-left: 14px;

                    &:hover {
                        background: $primary_color;
                        color: #111111;
                        border: 1px solid $primary_color;
                    }
                }

                &:first-child a {
                    background: $primary_color;
                    color: #111111;
                    border: 1px solid $primary_color;
                }
            }
        }

        .life-style span {
            font-size: 12px;
            font-weight: 400;
            color: #505050;
            margin-right: 12px;

            &:last-child {
                margin-right: 0;
            }

            i {
                margin-right: 4px;
            }

            a {
                font-size: 12px;
                font-weight: 400;
                color: #505050;

                i {
                    font-size: 12px;
                    margin-right: 4px;
                }

                &:hover {
                    color: $primary_color;
                }
            }

            &.author a {
                color: #777777;

                &:hover {
                    color: $primary_color;
                }
            }
        }
    }
}

/* ------------------------------------
19. Blog Sidebar Start here
---------------------------------------*/
.sidebar-area {
    .search-box {
        margin-top: -6px;

        input {
            background: #f0f0f0;
            border: none;
            color: #111111;
            font-weight: 600;
        }

        span {
            font-size: 18px;
            color: #111111;
            font-weight: 500;
            text-transform: uppercase;
            display: block;
            margin-bottom: 25px;
        }

        .box-search {
            position: relative;

            .form-control {
                border-radius: 0;
                box-shadow: none;
                color: #111111;
                padding: 5px 20px;
                height: 45px;
            }
        }

        .btn {
            position: absolute;
            right: 5px;
            background: transparent;
            border: none;
            top: 6px;

            &:hover i {
                color: $primary_color;
            }
        }
    }

    span.title {
        font-size: 18px;
        color: #111111;
        font-weight: 500;
        text-transform: uppercase;
        display: block;
        font-family: 'Poppins', sans-serif;
        background: $primary_color;
        padding: 12px 20px;
        margin: 0 0 25px;
    }

    .recent-post-area {
        ul li {
            margin: 0 0 20px;
            padding: 0 0 20px;
            border-bottom: 1px solid #ddd;

            img {
                transition: all 0.4s ease 0s;
                cursor: pointer;
            }

            h4 {
                margin: 0;
                line-height: 20px;

                a {
                    font-size: 15px;
                    font-weight: 500;
                    color: #111111;
                    font-family: 'Poppins', sans-serif;
                }
            }

            span {
                font-size: 12px;
                margin-right: 12px;

                a {
                    font-size: 12px;
                    font-weight: 300;
                    color: #505050;

                    i {
                        font-size: 12px;
                    }
                }

                i {
                    font-size: 12px;
                    margin-right: 4px;
                }
            }

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                a {
                    color: $primary_color;
                }

                img {
                    opacity: .5;
                }
            }
        }
    }
}

/* ------------------------------------
20  Footer Section Start here
---------------------------------------*/
.footer-section {
    background-color: #111111;
    color: #afb0b2;

    .footer-title {
        margin-bottom: 35px;
        padding-bottom: 12px;
        border-bottom: 2px solid #999;
        color: #fff;
        font-size: 18px;
        position: relative;
        font-weight: 500;
        text-transform: capitalize;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -2px;
            height: 2px;
            width: 50px;
            background-color: $primary_color;
        }
    }

    .footer-top {
        padding: 100px 0 95px;

        .about-widget p:last-child {
            margin-bottom: 0;
        }

        .recent-post-widget .post-item {
            display: flex;
            align-items: center;

            .post-date {
                width: 70px;
                height: 65px;
                flex: 0 0 70px;
                text-align: center;
                background-color: $primary_color;
                color: #111111;
                margin-right: 15px;

                > span {
                    display: block;

                    &:first-child {
                        margin-top: 6px;
                    }

                    &:last-child {
                        font-size: 13px;
                    }
                }
            }

            .post-title {
                font-size: 15px;
                line-height: 24px;
                margin-bottom: 0;

                a {
                    color: #afb0b2;
                    font-weight: 400;

                    &:hover,
                    &:focus {
                        color: #fff;
                    }
                }
            }

            .post-category {
                font-size: 13px;
            }

            + .post-item {
                margin-top: 15px;
                padding-top: 15px;
                border-top: 1px solid rgba(102, 102, 102, 0.5);
            }
        }

        .sitemap-widget {
            overflow: hidden;

            li {
                a {
                    color: #afb0b2;
                    display: block;
                    border-bottom: 1px solid rgba(102, 102, 102, 0.5);
                    position: relative;
                    padding: 4px 0 4px 14px;

                    &:hover,
                    &:focus {
                        color: #fff;
                    }

                    &:after {
                        content: "\f105";
                        font-family: "Font Awesome 5 Free";
                        font-size: 14px;
                        left: 0;
                        position: absolute;
                        top: 4px;
                        color: #afb0b2;
                        font-weight: 400;
                    }
                }

                &:last-child a {
                    border-bottom: 0;
                }
            }
        }
    }
}

.footer-bottom {
    padding: 20px 0;
    border-top: 1px solid #303030;
    font-size: 14px;

    .copyright {
        p {
            margin-bottom: 0;
        }

        a {
            color: $primary_color;

            &:hover,
            &:focus {
                color: #afb0b2;
            }
        }
    }

    .footer-bottom-share {
        display: inline-block;

        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                display: inline-block;

                + li {
                    margin-left: 10px;
                }

                a {
                    font-size: 15px;
                    display: block;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    line-height: 32px;
                    text-align: center;
                    color: #fff;
                    transition: all 0.4s ease 0s;
                    background: #383838;

                    &:hover {
                        color: $primary_color;
                    }
                }
            }
        }
    }
}

/* ------------------------------------
21. Breadcrumbs Start here
  ---------------------------------------*/
.rs-breadcrumbs {
    position: relative;
    overflow: hidden;

    &:after {
        position: absolute;
        height: 100%;
        width: 100%;
        content: "";
        top: 0;
        left: 0;
        background: linear-gradient(#111111 40%, $primary_color);
        opacity: 0.8;
    }

    img {
        width: 100%;
    }

    .breadcrumbs-inner {
        position: relative;
        width: 100%;
        text-align: center;
        z-index: 1;
        max-width: 100%;

        .page-title {
            margin-top: 6px;
            font-size: 36px;
            margin-bottom: 10px;
            letter-spacing: 0.5px;
            color: #ffffff;
            font-weight: 600;
        }

        ul {
            display: inline-block;
            padding: 4px 20px;

            li {
                color: $primary_color;
                display: inline-block;
                font-size: 15px;
                font-weight: 600;

                a {
                    position: relative;
                    padding-right: 30px;
                    transition: 0.3s;
                    color: #ffffff;

                    &:hover {
                        color: $primary_color;
                    }

                    &:after {
                        content: "\f0c1";
                        font-family: "Font Awesome 5 Free";
                        font-size: 15px;
                        color: #fff;
                        font-weight: 700;
                        display: block;
                        bottom: -4px;
                        right: 5px;
                        text-align: center;
                        position: absolute;
                    }
                }
            }
        }
    }
}

/*....................................
26. RS Point table css star Here
....................................*/
.rs-point-table {
    h3 {
        font-size: 24px;
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
        margin: 0;
    }

    .point-menu li {
        display: inline-block;
        padding: 0 45px 20px 0;

        a {
            color: #505050;
            font-weight: 600;
            border: none;
            text-transform: uppercase;

            &:hover {
                color: $primary_color;
                background-color: unset;
            }
        }

        &.active {
            a {
                color: $primary_color !important;
            }
        }
    }

    .tab-content {
        overflow-x: auto;

        table {
            border-left: 1px solid #e1e1e1;
            border-right: 1px solid #e1e1e1;

            .team-name {
                width: 150px;
            }

            tr {
                td {
                    padding: 8px 4px;
                    width: 120px;
                    border-bottom: 1px solid #e1e1e1;
                    color: #505050;

                    &:first-child {
                        width: 45px;
                    }

                    &.name {
                        min-width: 250px;
                        @media only screen and (max-width: $break-mobile) {
                            min-width: unset;
                        }
                    }

                    &.statistics {
                        min-width: 150px;

                        h3 {
                            padding: 5px 0;
                        }

                        @media only screen and (max-width: $break-mobile) {
                            min-width: unset;
                        }
                    }
                }

                &:hover {
                    background-color: $primary_color;
                }

                &:first-child {
                    background-color: rgba(0, 0, 0, 0.08);

                    td {
                        border: none;
                        color: $primary_color;
                        vertical-align: bottom;

                        img {
                            max-width: 30px;
                            max-height: 30px;
                        }

                        h3 {
                            font-size: 10px;
                            color: $primary_color;
                            text-transform: initial;
                            font-weight: normal;
                        }
                    }
                }
            }
        }

        .tab-pane {
            h3 {
                padding: 35px 12px 5px 0;

                &:first-child {
                    padding-top: 0;
                }
            }
        }
    }
}


@media only screen and (max-width: $break-desktop) {
    .header-middle-area {
        .logo {
            img {
                max-height: 90px;
            }
        }
    }

    .rs-blog .single-blog-slide {
        min-height: 325px;

        .blog-details h3 {
            line-height: 18px;

            a {
                font-size: 15px;
            }
        }
    }
}

@media(min-width: $break-tablet) {
    .rs-point-table .tab-content {
        overflow-x: auto;

        table {
            tr {
                td {
                    padding: 8px 12px !important;
                }
            }
        }
    }
}

@media only screen and (min-width: $break-tablet) and (max-width: $break-desktop) {
    .today-match-teams {
        .today-score {
            .today-match-team {
                display: block;
                margin: 0 auto;
                width: 100%;
            }

            .today-final-score {
                display: block;
                width: 100%;
                margin: 10px auto;
                line-height: unset;

                h4 {
                    margin: 10px;
                }
            }
        }

        .today-results-table tr td {
            padding: 7px 12px;
        }
    }

    .latest-news .small-news .news-text h3 {
        line-height: 14px;

        a {
            font-size: 13px;
        }
    }

    .latest-news-slider .slick-arrow {
        right: -90px;

        &.slick-next {
            right: -135px;
        }
    }
}

@media only screen and (max-width: $break-tablet) {
    .sec-spacer {
        padding: 80px 0;
    }

    .nav-menu {
        div.sub-menu-close {
            background: #f6f3ed;
        }
    }

    .main-section {
        .row {
            h1 {
                font-size: 25px;
            }

            .time_circles {
                > div span {
                    font-size: 16px !important;
                }

                > div h4 {
                    font-size: 10px !important;
                }
            }
        }

        &.big-image {
            .container {
                > .row {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
    }

    .our-team-section {
        &.team-inner-page {
            padding-bottom: 50px;
        }

        &.pb-100 {
            margin-bottom: 40px;
        }

        .separator-70 {
            height: 35px;
        }

        &.pb-65 {
            padding-bottom: 15px;
        }
    }

    .pb-70 {
        padding-bottom: 50px;
    }

    .rs-blog {
        .sidebar-area {
            overflow: hidden;
            clear: both;
            padding-top: 60px;
        }

        .single-blog-slide {
            min-height: 510px;
        }
    }

    .upcoming-section {
        padding: 30px 0;

        span {
            font-size: 14px;
        }

        h2 {
            font-size: 35px;
        }
    }

    .rs-team-single-section {
        .rs-team-single-image {
            margin-bottom: 40px;
        }

        .team-single-details-text {
            padding: 0 0 30px;
        }

        .rs-team-single-text .single-details .single-team-text {
            .text-section p {
                margin: 15px 0 0;
            }

            .signature-section .sign-left h1 {
                margin: 20px 0 0;
            }
        }
    }

    .single-blog-details {
        padding-bottom: 70px;
    }

    .header-middle-area {
        background: #111111 !important;

        .search {
            top: auto;
            bottom: 14px;
            right: 14px;
            transform: translateY(0);

            a {
                color: $white !important;
            }

            .search-btn {
                color: $white !important;
                margin: 17px 18px;
            }
        }

        .container {
            width: 100%;

            .mobile-menu {
                padding-left: 0;
                padding-right: 0;
            }

            .main-menu {
                .navbar {
                    .navbar-toggler {
                        margin: 0 auto;
                    }
                }

                .navbar-expand-md {
                    margin-top: 45px;
                }
            }
        }

        .logo {
            position: relative;
            text-align: center;
            padding-bottom: 10px;
            left: 0;

            img {
                position: relative;
                max-height: 80px;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }
    }

    .footer-section {
        .footer-top {
            padding: 80px 0;

            [class*="col-"] + [class*="col-"] {
                margin-top: 40px;
            }
        }

        .about-widget p:first-child {
            margin-bottom: 12px;
        }
    }

    .sidebar-area {
        .recent-post-area {
            margin-top: 30px;

            ul li:last-child {
                padding-bottom: 8px;
            }
        }

        &.right-side-area {
            padding-top: 45px;
        }

        &.left-side-area {
            padding-bottom: 45px;
        }
    }

    .rs-breadcrumbs img {
        min-height: 200px;
    }

    .latest-video-section .latest-video > img {
        width: 100%;
    }

    .today-match-teams .today-score {
        .today-match-team {
            float: none;
            display: inline-block;
        }

        .today-final-score {
            float: none;
            display: inline-block;
        }
    }
}

@media only screen and (max-width: $break-big-mobile) {
    .container {
        padding-right: 25px;
        padding-left: 25px;
    }

    .header-top-area {
        .social-media-area ul {
            text-align: center;
        }

        .header-top-left ul {
            text-align: center;

            li {
                float: none;
                display: inline-block;

                &:last-child {
                    position: relative;
                }

                ul {
                    right: 0;
                }
            }
        }
    }

    .upcoming-section {
        .vs {
            padding: 15px 0 10px;
        }

        .first {
            margin-bottom: 30px;
        }

        .owl-nav {
            margin-top: 30px;

            .owl-prev {
                display: inline-block;
                margin: 0 15px;
            }

            .owl-next {
                display: inline-block;
                margin: 0 15px;
            }
        }

        .last {
            margin-top: 30px;
        }

        h2 {
            font-size: 25px;
            line-height: 30px;
        }
    }

    .client-logo-section {
        .single-logo {
            margin-bottom: 20px;
        }
    }

    .footer-bottom {
        .ft-bottom-right {
            padding-top: 10px;
            text-align: center;
        }

        .copyright {
            text-align: center;
        }
    }

    .single-blog-details {
        blockquote {
            padding: 16px 25px 20px 63px;
            font-size: 16px;
        }
    }

    .header-middle-area {
        .container {
            padding-right: 15px;
            padding-left: 15px;

            .main-menu {
                .navbar-expand-md {
                    margin-top: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: $break-mobile) {
    .header-middle-area {
        .logo {
            height: auto;

            img {
                position: relative;
            }

            .container {
                .main-menu {
                    .navbar-expand-md {
                        margin-top: 0;
                    }
                }
            }
        }

        .navbar {
            // text-align: center;
            .navbar-toggler {
                margin: 0 auto;
            }

            .navbar-collapse {
                border: 0;
                box-shadow: none;
            }
        }
    }

    .single-blog-details {
        .col-xs-6 {
            width: 100%;
            float: none;
        }

        blockquote {
            font-size: 14px;
        }
    }

    .slider-main {
        .btm-date {
            display: none;
        }

        .btn-slider {
            margin-top: 20px;
        }

        &.slider-section4 .slider-text {
            .CountDownTimer {
                margin: 10px auto 5px;
                max-width: 300px;
            }

            h1 {
                font-size: 20px;
            }
        }
    }

    .gallery-section-area {
        .grid {
            .grid-item::before {
                bottom: -150px;
            }

            .single-gallery::before {
                bottom: -150px;
            }

            .grid-item::after {
                top: -150px;
            }

            .single-gallery::after {
                top: -150px;
            }
        }
    }

    .title-bg {
        font-size: 20px;
        margin-bottom: 35px;
    }

    .rs-point-table {
        .tab-content table .team-name {
            width: 200px;
        }

        .point-menu li {
            padding: 0 25px 15px 0;
        }
    }

    .rs-blog .col-xs-6 {
        width: 100%;
        float: none;
    }

    .logo img {
        max-height: 120px;
    }

    .slider-sections-area3 #slider-carousel ul li {
        width: 120px !important;
    }

    .rs-breadcrumbs .page-title {
        font-size: 26px;
    }
}

@media only screen and (max-width: $break-mobile) {
    .client-logo-section {
        .col-xs-6 {
            width: 100%;
            float: none;
        }

        .single-logo {
            max-width: 250px;
            margin: 0 auto;
        }
    }

    .container {
        padding-right: 15px;
        padding-left: 15px;
    }

    .header-top-area {
        .social-media-area {
            .log {
                margin-left: 0;

                a {
                    font-size: 13px;
                }
            }

            ul li a {
                margin-right: 6px;
                font-size: 13px;
            }

            .sign a {
                font-size: 13px;
            }
        }

        .header-top-left ul li {
            &:last-child {
                margin-left: 10px;
                font-size: 6px;
            }

            a {
                font-size: 13px;
            }
        }
    }

    .rs-point-table {
        .point-menu li {
            padding-right: 18px;

            a {
                font-size: 13px;
            }
        }

        .tab-content table {
            border-left: 0;
            border-right: 0;

            tr {
                margin-bottom: 25px;
                border-top: 1px solid #e1e1e1;
                border-left: 1px solid #e1e1e1;
                border-right: 1px solid #e1e1e1;

                td {
                    width: auto;
                    border: 1px solid #e1e1e1;
                    font-size: 12px;
                    padding: 5px;
                    min-width: 25px;

                    &:first-child {
                        width: auto;
                    }
                }

                &:first-child {
                    margin-bottom: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .team-inner-page .col-xs-6 {
        width: 100%;
        float: none;
    }

    .rs-blog {
        .single-blog-slide {
            min-height: auto;

            .images {
                &.images-background {
                }
            }
        }
    }

    .rs-breadcrumbs img {
        min-height: 150px;
    }

    .point-list table.point-table {
        th {
            padding: 7px 12px;
            font-size: 13px;
        }

        td {
            padding: 7px 12px;
            font-size: 13px;
        }
    }

    .latest-news .small-news {
        .news-img {
            float: none;
            margin-right: 0;
            margin-bottom: 10px;
        }

        + .small-news {
            margin-top: 30px;
        }
    }

    .footer-bottom .copyright p {
        font-size: 13px;
    }
}
