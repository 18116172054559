@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html, body {
    min-height: 100%;
    height: auto;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: relative;

    *, *:before, *:after {
        box-sizing: border-box;
    }

    .clear {
        clear: both;
    }

    .navbar-collapse {
        flex-direction: row-reverse;
    }
    
    .slider-container {
        .slider-item {
            img {
                margin: 0 auto;
            }
        }
    }
}
